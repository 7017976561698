import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next'
import $ from 'jquery';
import { useSpring, animated } from '@react-spring/web'
const moment = require("moment");

const ICO = () => {
    const startDate = 1696204800000 // 02-10-2023
    const deadlineDate = 1698796800000 // 01-11-2023

    const [icoUSDT, setIcoUSDT] = useState(0);
    const [icoUSDC, setIcoUSDC] = useState(0);
    const [icoBUSD, setIcoBUSD] = useState(0);
    const totalAmountGap = 100000

    const [t] = useTranslation();
    const [deadlineDay, setDeadlineDay] = useState(0);
    const [deadlineHour, setDeadlineHour] = useState(0);
    const [deadlineMin, setDeadlineMin] = useState(0);
    const [deadlineSec, setDeadlineSec] = useState(0);

    const [hardCap, setHardCap] = useState(2);
    const [callTimeGap, setCallTimeGap] = useState(300000);
    const [totalAmount, setTotalAmount] = useState(0);

    const props = useSpring({
        from: { usd: 0, fee: 0, usdt: 0, usdc: 0, busd: 0, eth: 0, bnb: 0, matic: 0},
        to: { usd: totalAmount, fee: parseInt(totalAmount / 0.0025), usdt: icoUSDT / (hardCap * totalAmountGap) * 100 , usdc: icoUSDC / (hardCap * totalAmountGap) * 100, busd: icoBUSD / (hardCap * totalAmountGap) * 100, eth: 40 / (hardCap * totalAmountGap) * 100, bnb: 53 / (hardCap * totalAmountGap) * 100, matic: 1495 / (hardCap * totalAmountGap) * 100},
        // delay: 500,
        // config: {tension: 10, friction: 100}
    })

    const setDeadlineTimer = () => {
        let deadlineTime = startDate <= moment.utc().valueOf() ? parseInt(deadlineDate - moment.utc().valueOf()) : parseInt(startDate - moment.utc().valueOf())
        if(deadlineTime < 0) return
        setDeadlineDay(parseInt(deadlineTime / (24 * 60 * 60 * 1000)))
        deadlineTime = parseInt(deadlineTime % (24 * 60 * 60 * 1000))
        setDeadlineHour(parseInt(deadlineTime / (60 * 60 * 1000)))
        deadlineTime = parseInt(deadlineTime % (60 * 60 * 1000))
        setDeadlineMin(parseInt(deadlineTime / (60 * 1000)))
        deadlineTime = parseInt(deadlineTime % (60 * 1000))
        setDeadlineSec(parseInt(deadlineTime / 1000))
    }
   
    const callAmountApi = async () => {
        try {
            localStorage.icoData = JSON.stringify(await fetch(process.env.REACT_APP_APIURL).then((res) => res.json()))
        } catch (error) {
            console.log("api error", error)
        }
    }

    const updateICO_Amount = async () => {
        // if(!localStorage.callTimer) localStorage.callTimer = 0
        
        // if(parseInt(localStorage.callTimer) + callTimeGap < moment.utc().valueOf()) {
        //     await callAmountApi()
        //     localStorage.callTimer = moment.utc().valueOf()
        // }
        // const icoData = JSON.parse(localStorage.icoData)
        // const icoUSDT = icoData.eth.usdt + icoData.bnb.usdt + icoData.pol.usdt
        // const icoUSDC = icoData.eth.usdc + icoData.bnb.usdc + icoData.pol.usdc
        // const icoBUSD = icoData.eth.busd + icoData.bnb.busd + icoData.pol.busd
        // const totalAmount = icoUSDT + icoUSDC + icoBUSD
   
        const totalAmount = 291292 + 1247 + 529 + (1495) + (40) + (53)
        setIcoUSDT(291292)
        setIcoUSDC(1247)
        setIcoBUSD(529)
        setTotalAmount(totalAmount)
        setHardCap((2*totalAmountGap) <= totalAmount ? parseInt(totalAmount / totalAmountGap) + 1 : 2)
    }

    const callSearchApi = async () => {
        if(startDate <= moment.utc().valueOf() && moment.utc().valueOf() <= deadlineDate) {
            return await $.ajax({
                url: process.env.REACT_APP_APIURL,
                method: "GET",
                dataType:'json',
            })
            .done((res) => {
                if(!res) return console.log("Failed api call")
                $(".serchTokenizingInfo").html(`
                    지갑주소 : 0x0000000000000000000000000000000000000000<br/><br/>

                    PreICO: 0 ϕ<br/>
                    First ICO: 0 ϕ<br/>
                    SecondLife: 0 ϕ<br/><br/>

                    교환 가능: 0 ϕ<br/>
                    교환 완료: 0 ϕ<br/>
                    잔여: 0 ϕ<br/>
                `)
                // $(".serchTokenizingInfo").html(`
                //     지갑주소 : `+res.wallet+`<br/><br/>

                //     PreICO: `+res.wallet+` ϕ<br/>
                //     First ICO: `+res.wallet+` ϕ<br/>
                //     SecondLife: `+res.wallet+` ϕ<br/><br/>

                //     교환 가능: `+res.wallet+` ϕ<br/>
                //     교환 완료: `+res.wallet+` ϕ<br/>
                //     잔여: `+res.wallet+` ϕ<br/>
                // `)
            })
            .fail((e) => { console.log("failed call api",e) })
        } else {
            return $(".serchTokenizingInfo").html("Closed")
        }
    }

    const seachTokenizing = async () => {
        const TokeninzingWallet = $('#TokeninzingWallet').val()
        if(!(/^0x[a-fA-F0-9]{40}$/g).test(TokeninzingWallet)) return $(".serchTokenizingInfo").html("유효하지 않은 지갑주소 입니다.")
        await callSearchApi()
    }

    useEffect(() => {       
        const f_updateICO_Amount = async () => {
            await updateICO_Amount()
        }
        f_updateICO_Amount()
        setInterval(() => {
            setDeadlineTimer()
        }, 1000)
    }, [])

    // const [props, api] = useSpring(() => ({
    //     from: { amount: 0 },
    //     // to: { amount: 100000 },
    // }))
    // const render = () => {
    //     api.start({
    //         // from: {amount: 0},
    //         to: {amount: 10000}
    //     })
    // }

    return (
        <>
            {/* <div onClick={() => render()}> <animated.div></animated.div> </div> */}
            
            <div className='mainbg bgImgContaner'>
                <img src={require('../../img/mainBg.png')} loading="lazy"/>
            </div>
            <div id='main' className='content'>
                <div className='mainInfo'>
                    <div className='notice'>
                        <p className='fsMT'>{t('notice_MT_v2')}</p>
                        {/*<p className='fsST'>{t('notice_ST_v2')}</p>
                        <p className='fsNT'><Trans>notice_NT_v2</Trans>
                        </p>*/}
                        <p className='fsST'>Event ends</p>
                        {/*<div className='hardCap'>
                            <p className='fsST'>1 Fee : 0.0025 USD</p>
                        </div>*/}
                        {/* <p className='bold6'>{t('notice_preContent_v2')} <a href='' target={"_blank"}>&nbsp;[Link]</a></p> */}
                    </div>
                    <div className='deadlineInfo'>
                        <div className='hardCap'>
                            {/* <p className='fsST'>{t('hardCap_Remaining_v2')}</p> */}
                            <p className='fsST'>{t('hardCap_Remaining_v3')}</p>
                            <animated.div className='fsST'>294,656 USD</animated.div> 
                            <animated.div className='fsNT'>117,862,400 Fee</animated.div> 
                        </div>
                            {/*<a className='purchaseButton bold6' onClick={()=>{$("html, body").animate({scrollTop: ($("#purchase").offset()).top}, 500)}}><p>BUY</p></a>*/}
                            <p className='fsNT eventTime'>{(moment.utc().valueOf() < startDate) ? t('hardCap_Starting_v2') : t('hardCap_DeadLine_v2')}</p>
                        <div id='timer'>
                            <div><span>{deadlineDay}</span><span className='timerFormat'>{t('hardCap_DeadLineDay')}</span></div>
                            <span>:</span>
                            <div><span>{deadlineHour}</span><span className='timerFormat'>{t('hardCap_DeadLineHour')}</span></div>
                            <span>:</span>
                            <div><span>{deadlineMin}</span><span className='timerFormat'>{t('hardCap_DeadLineMin')}</span></div>
                            <span>:</span>
                            <div><span>{deadlineSec}</span><span className='timerFormat'>{t('hardCap_DeadLineSec')}</span></div>
                        </div>
                    </div>
                </div>
                <div className='progressBar'>
                    <div id='maxBar'>
                        <div className='capBar'>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                            <div className='softCap sl_Cap10m'></div>
                        </div>
                        <div id='currentBar'>
                            <animated.div id='usdtBar' className='usdtIndex' style={{width: props.usdt.to(val => val + "%")}}></animated.div>
                            <animated.div id='usdcBar' className='usdcIndex' style={{width: props.usdc.to(val => val + "%")}}></animated.div>
                            <animated.div id='busdBar' className='busdIndex' style={{width: props.busd.to(val => val + "%")}}></animated.div>
                            <animated.div style={{ background:"darkgray", width: props.eth.to(val => val + "%")}}></animated.div>
                            <animated.div style={{ background:"#FBB80A", width: props.bnb.to(val => val + "%")}}></animated.div>
                            <animated.div style={{ background:"#884CEB", width: props.matic.to(val => val + "%")}}></animated.div>
                        </div>
                    </div>
                    <div className='capBar'>
                        <p className="softCapText sl_CapText10m">{hardCap * 10}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 20}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 30}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 40}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 50}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 60}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 70}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 80}K</p>
                        <p className="softCapText sl_CapText10m">{hardCap * 90}K</p>
                    </div>
                    <div className='IcoCurrencyList'>
                        <div><img src={require('../../img/icon_usdt.webp')} />USDT(ETH, BNB, MATIC)</div>
                        <div><img src={require('../../img/icon_usdc.webp')} />USDC(ETH, BNB, MATIC)</div>
                        <div><img src={require('../../img/icon_busd.png')} />BUSD(ETH, BNB, MATIC)</div>
                        <div><img src={require('../../img/icon_eth.png')} />ETH</div>
                        <div><img src={require('../../img/icon_bnb.png')} />BNB</div>
                        <div><img src={require('../../img/icon_matic.png')} />MATIC</div>
                    </div>
                </div>
                <div className='tokenInfo'> 
                    <p className='bold6'><Trans>polygonFeeText_v2</Trans></p>
                    <div className='hardCap'>
                        <p className='fsNT'>
                            Name : Mojaik FEE Token<br/>
                            Symbol : FEE<br/>
                            Decimals: 18<br/>
                            TotalSupply: 50,000,000,000 ϕ<br/>
                            Contract: 0xd01761ad2c5eb0d6d6af52b0c48c0c0ee4bcbaf1<br/>
                            Explorer: &nbsp;<a href='https://polygonscan.com/token/0xd01761ad2c5eb0d6d6af52b0c48c0c0ee4bcbaf1' target={"_blank"}>[Link]</a><br/>
                        </p>
                    </div>
                    {/* <div className='tokenizing'>
                        <p className='fsST'>{t('polygonFeeSearch_v2')}</p>
                        <div className='serchTokenizing'>
                            <input id="TokeninzingWallet" className='serchTokenizingBar' placeholder={t('polygonFeeHolder_v2')}></input>
                            <a className='serchTokenizingButton bold6' onClick={()=>{seachTokenizing()}}><p>SEARCH</p></a>
                        </div>
                        <div className='serchTokenizingInfo'></div>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default ICO;