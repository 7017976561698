import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import './font/font.css';

import Root from './page/root';
import Test from './page/test';
import NoPage from './page/noPage';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Root/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
