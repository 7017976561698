import { useTranslation } from 'react-i18next'

const Owner = () => {
    const [t] = useTranslation();
    return (
        <>
            <div id='team' className='teamPart content'>
                <div className='teamContent'>
                    <p className='fsMT'>{t('team_MT')}</p>
                    <div className='director'>
                        <p className='fsST'>{t('team_member_directors')}</p>
                        <div className='ceo'>
                            <img className='profile' src={require('../../img/ceo.png')} />
                            <div className='info'>
                                <br/>
                                <p className='nickname'>
                                    Seung Il Chung | @solomon
                                    <a href='https://www.linkedin.com/in/blaachat/' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                </p>
                                <p>Co-Founder | CEO</p>
                                <br/>
                                <p>{t('team_member_directors_ceoInfo')}</p>
                                <br/>
                                <p>{t('team_member_directors_ceoInfo_detail1')}</p>
                                <p>{t('team_member_directors_ceoInfo_detail2')}</p>
                                <p>{t('team_member_directors_ceoInfo_detail3')}</p>
                                <p>{t('team_member_directors_ceoInfo_detail4')}</p>
                                <p>{t('team_member_directors_ceoInfo_detail5')}</p>
                                <p>{t('team_member_directors_ceoInfo_detail6')}</p>
                                <p>{t('team_member_directors_ceoInfo_detail7')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Owner;