import Intro from './intro';

const Home = () => {
    return (
        <>
        <Intro/>
        </>
    );
};

export default Home;