import { useTranslation } from 'react-i18next'

const Employee = () => {
    const [t] = useTranslation();
    return (
        <>
            <div className='teamPart content'>
                <div className='teamContent'>
                    <div className='developer'>
                        <p className='fsST'>Main Developer</p>
                        
                        <div className='member'>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Aron.png')} />
                                <p className='nickname'>
                                    Aron Lee
                                    <a href='https://www.linkedin.com/in/jonghun-lee-08505b288/' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                </p>
                                <p>Chief Software Engineer</p>
                            </div>
                        </div>
                        <div className='member'>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Liam.png')} />
                                <p className='nickname'>
                                    Liam Choi
                                    <a href='https://www.linkedin.com/in/sangjun-choi-91632b152/' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                </p>
                                <p>Software Engineer Manager</p>
                            </div>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_John.png')} />
                                <p className='nickname'>
                                    John
                                    <a href='https://www.linkedin.com/in/anh-tran-tuan-384343ab' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                </p>
                                <p>Software Engineer</p>
                            </div>
                            {/* <div className='info'>
                                <img className='profile' src={require('../../img/member_Cao.png')} />
                                <p className='nickname'>
                                    D.K Cao
                                    <a href='https://www.linkedin.com/in/lcv284' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                </p>
                                <p>Software Engineer</p>
                            </div> */}
                            <div className='info'>
                                    <img className='profile' src={require('../../img/member_Zack.png')} />
                                    <p className='nickname'>
                                        Zack Nguyen<a href='www.linkedin.com/in/nguyenquanit17' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                    </p>
                                    <p>Software Engineer</p>
                            </div>
                        </div>
                        <div className='member'>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Cao.png')} />
                                <p className='nickname'>
                                    D.K Cao
                                    <a href='www.linkedin.com/in/lcv284' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                </p>
                                <p>Software Engineer</p>
                            </div>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Henry.png')} />
                                <p className='nickname'>
                                    Henry
                                    <a href='https://www.linkedin.com/in/ruon-chau-1b64b0243/' target={"_blank"}><img src={require('../../img/linkedin_color.png')} /></a>
                                </p>
                                <p>Software Engineer</p>
                            </div>
</div>
                    </div>
                    <div className='management'>
                        <p className='fsST'>{t('team_member_management')}</p>
                        <div className='member'>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Tharo.png')} />
                                <p className='nickname'>Tharo</p>
                                <p>Designer</p>
                            </div>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Mamoiris.png')} />
                                <p className='nickname'>Mamoiris</p>
                                <p>Manager</p>
                            </div>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Yink.png')} />
                                <p className='nickname'>Yink</p>
                                <p>Manager</p>
                            </div>
                            <div className='info'>
                                <img className='profile' src={require('../../img/member_Talitha.png')} />
                                <p className='nickname'>Talitha</p>
                                <p>Manager</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default Employee;