import Owner from './onwer';
import Employee from './employee';

const Team = () => {
	return (
        <>
		<Owner />
        <Employee />
        </>
	);
};

export default Team;