import { useTranslation } from 'react-i18next'
import $ from 'jquery';

const Intro = () => {
    const [t] = useTranslation();

    const movingContent = (item) =>{
        $("html, body").animate({scrollTop: ($((item).toString()).offset()).top}, 500)
    }

    return (
        <>
            <div id='intro'>
                <img className='bgImage' src={769 < window.innerWidth ? require('../../img/introBg.png') : require('../../img/intro_bg_m.png')} />
                <div className='introBox'>
                    <div className='introContent'>
                        <img className='introLogo' src={require('../../img/introLogo.png')} />
                        <div class="hardCap">
                            <p class="fsST">
                                {t('intro_Content')}
                                <span className='boldText'>"{t('intro_MainContent')}"</span>
                            </p>
                            <div className='moveExchangePart fsST'>
                                <div className='' onClick={() => movingContent('#exchange_Network')}>{t('purchase_network_Title')}</div>
                                <div className='' onClick={() => movingContent('#exchange_Swap')}>{t('purchase_swap_Title')}</div>
                                <div className='' onClick={() => movingContent('#exchange_Staking')}>{t('purchase_staking_Title')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Intro;