import Metamask from './metamask';
import Purchase from './purchase';
import Swap from './swap';
import Staking from './staking';

const Exchange = () => {
	return (
        <>
		<Metamask />
        {/* <Purchase /> */}
        <Swap />
        <Staking />
        </>
	);
};

export default Exchange;