import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next'
import $ from 'jquery';
const moment = require("moment");

const Purchase = () => {
    const startDate = 1696204800000 // 02-10-2023
    const deadlineDate = 1698796800000 // 01-11-2023
    const [t] = useTranslation();
    const [icoWalletAddress, setIcoWalletAddress] = useState('');
    const [activedCopy, setActivedCopy] = useState('');

    const ico_usd_rate = 769.23;
    const ico_krw_rate = 1261.91;
    const sc_usd_rate = 0.00025;
    const max_ico_rate = 10;
    const [amount_ico_usd, setAmount_ico_usd] = useState(0);
    const [amount_ico_krw, setAmount_ico_krw] = useState(0);
    const [amount_sc_usd, setAmount_sc_usd] = useState(0);

    const copyText = (path, text) => {
        $('.networkName a').css('color', '#4080FF')
        $('.copyAddress').css('background', '#DC3545')
        const mergeClass = '.'+ path
        if(path === 'copyAddress') $(mergeClass).css('background', 'darkslateblue')
        else $(mergeClass).css('color', 'darkslateblue')
        navigator.clipboard.writeText(text)
    }
    const updateTokenizingValue = () => {
        let ico_usd = parseInt($("#enter_ico_usd").val())
        let ico_krw = parseInt($("#enter_ico_krw").val())
        let sc_usd = parseInt($("#enter_sc_usd").val())

        if(isNaN(ico_usd) || ico_usd === "" || !(/[0-9]$/g).test(ico_usd)) {
            ico_usd = 0
            $("#enter_ico_usd").val(0)
        } else $("#enter_ico_usd").val(parseInt(ico_usd))
        if(isNaN(ico_krw) || ico_krw === "" || !(/[0-9]$/g).test(ico_krw)) {
            ico_krw = 0
            $("#enter_ico_krw").val(0)
        } else $("#enter_ico_krw").val(parseInt(ico_krw))
        if(isNaN(sc_usd) || sc_usd === "" || !(/[0-9]$/g).test(sc_usd)) {
            sc_usd = 0
            $("#enter_sc_usd").val(0)
        } else $("#enter_sc_usd").val(parseInt(sc_usd))

        const tokenizing_icoTotal = parseInt((ico_usd + parseInt(ico_krw / ico_krw_rate)) * ico_usd_rate *  max_ico_rate)
        const scTotal = parseInt(sc_usd / sc_usd_rate)
        const tokenizing_total = tokenizing_icoTotal + scTotal
        const swap_sc =  parseInt(tokenizing_total * 0.27)

        $("#tokenizing_ico").val(tokenizing_icoTotal.toLocaleString())
        $("#tokenizing_sc").val(scTotal.toLocaleString())
        $("#tokenizing_result").val((swap_sc).toLocaleString())

        const swap_default = parseInt((ico_usd + parseInt(ico_krw / ico_krw_rate)) * ico_usd_rate)
        const swap_result =  parseInt(swap_sc + swap_default)

        $("#swap_ico").val(swap_sc.toLocaleString())
        $("#swap_default").val(swap_default.toLocaleString())
        $("#swap_result").val((swap_result).toLocaleString())
    }

    useEffect(() => {
        setIcoWalletAddress(((startDate <= moment.utc().valueOf()) && (moment.utc().valueOf() < deadlineDate)) ? "0x9999d5f3750FE0667314dbA0E4e671561bDF797c" : "Closed")
        setActivedCopy('copyAddress')

        $("#enter_ico_usd").val(0)
        $("#enter_ico_krw").val(0)
        $("#enter_sc_usd").val(0)

        $("#tokenizing_ico").val(0)
        $("#tokenizing_sc").val(0)
        $("#tokenizing_result").val(0)
        $("#swap_ico").val(0)
        $("#swap_default").val(0)
        $("#swap_result").val(0)
    },[])

    useEffect(() => {
        updateTokenizingValue()
    },[amount_ico_usd, amount_ico_krw, amount_sc_usd])
    
    return (
        <>
            <div id='exchange_Purchase' className='exchangePart content'>
                <div className='intro'>
                    <p className='fsMT'>{t('purchase_IntroTitle_v2')}</p>
                    <div className='targetAddress red'>
                        <div className='walletForm'>
                            <p>{icoWalletAddress}</p>
                            <a className="copyAddress" onClick={() => copyText(activedCopy)}>Copy</a>
                        </div>
                    </div>
                    <p className='fsNT'>
                        <Trans>purchase_IntroContent_Pre_v2</Trans>
                        &nbsp;<img src={require('../../img/icon_usdt.webp')} />USDT,
                        &nbsp;<img src={require('../../img/icon_usdc.webp')} />USDC,
                        &nbsp;<img src={require('../../img/icon_busd.webp')} />BUSD
                        &nbsp;<Trans>purchase_IntroContent_Suf_v2</Trans>                        
                    </p>
                    <p className='explanationText'>
                        <Trans>purchase_IntroContentText_v2</Trans>
                        {/* <span className='bold6'>{t('notice_preContent_v2')} <a href='' target={"_blank"}>&nbsp;[Link]</a></span> */}
                    </p>
                    <div className='calculatesTokenizing'>
                        <p className='fsNT'>{t('purchase_Tokenizing_Title')}</p>
                        <table>
                            <tr>
                                <td className='mergeCell' rowspan="2">First ICO</td>
                                <td>{t('purchase_Tokenizing_Quantity_pre')} USD {t('purchase_Tokenizing_Quantity_suf')}: </td>
                                <td><input id="enter_ico_usd" type='text' onChange={(e)=>setAmount_ico_usd(e.target.value)}></input> USD</td>
                            </tr>
                            <tr>
                                <td>{t('purchase_Tokenizing_Quantity_pre')} KRW {t('purchase_Tokenizing_Quantity_suf')}: </td>
                                <td><input id="enter_ico_krw" type='text' onChange={(e)=>setAmount_ico_krw(e.target.value)}></input> KRW (1 USD = 1261.91 KRW)</td>
                            </tr>
                            <tr>
                                <td>SecondLife </td>
                                <td>{t('purchase_Tokenizing_Quantity_pre')} USD {t('purchase_Tokenizing_Quantity_suf')}: </td>
                                <td><input id="enter_sc_usd" type='text' onChange={(e)=>setAmount_sc_usd(e.target.value)}></input> USD</td>
                            </tr>
                        </table>
                        <br/>
                        <p className='tokenizingCalculate'>
                            *{t('purchase_Tokenizing_Fee_Data')} ⇒ {t('purchase_Tokenizing_Fee_Coin')}<br/>
                            (&nbsp;<input type='text' id="tokenizing_ico" disabled></input>
                            &nbsp;+&nbsp;
                            <input type='text' id="tokenizing_sc" disabled></input>&nbsp;)
                            &nbsp;x&nbsp;
                            <input type='text' value="0.27" disabled></input>
                            &nbsp;=&nbsp;
                            <input type='text' id="tokenizing_result" disabled></input> Fee(Coin)&nbsp;[= A]<br/>
                            - ((First ICO {t('purchase_Tokenizing_Quantity_pre')} USD {t('purchase_Tokenizing_Quantity_suf')} x 769.23 x {t('purchase_Tokenizing_Multiple_10')}) + (SecondLife {t('purchase_Tokenizing_Quantity_pre')} USD {t('purchase_Tokenizing_Quantity_suf')} / 0.0025 x {t('purchase_Tokenizing_Multiple_10')})) x 0.27<br/><br/>
                        </p>
                        <p>
                            *{t('purchase_Tokenizing_Fee_Coin')}  ⇒ Mojaik Fee Token<br/>
                            <input type='text' id="swap_ico" disabled></input>
                            
                            &nbsp;+&nbsp;
                            <input type='text' id="swap_default" disabled></input>
                            &nbsp;=&nbsp;
                            <input type='text' id="swap_result" disabled></input> Mojaik Fee Token<br/>
                            - A + (First ICO {t('purchase_Tokenizing_Quantity_pre')} USD {t('purchase_Tokenizing_Quantity_suf')} x 769.23)
                        </p>
                    </div>
                    <p className='explanationText'>
                        {t('purchase_Tokenizing_Preico')}                        
                    </p>
                </div>
            </div>
        </>
    );
};

export default Purchase;