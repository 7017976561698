import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next'
import $ from 'jquery';

const moment = require("moment");

function Main() {
    const deadlineDate = 1672531199999
    const remainingTotal = 3076920000
    const hardcapAmount = 4000000
    const exFEE = 769.23
    const icoBonusGap = [500000, 1500000, 2500000, 3500000]
    let icoUSDT = 0
    let icoUSDC = 0
    let icoBUSD = 0

    let icoUSDM = 0
    let icoKRWM = 0
    let icoVNDM = 0

    const [t] = useTranslation();
    const [deadlineDay, setDeadlineDay] = useState(0);
    const [deadlineHour, setDeadlineHour] = useState(0);
    const [deadlineMin, setDeadlineMin] = useState(0);
    const [deadlineSec, setDeadlineSec] = useState(0);

    const [raisedUSDT, setRaisedUSDT] = useState('0');
    const [bonusAmount, setBonusAmount] = useState(10);
    const [remainingFee, setRemainingFee] = useState(0);
    const [openICO, setOpenICO] = useState(true);

    const setDeadlineTimer = () => {
        let deadlineTime = parseInt(deadlineDate - moment.utc().valueOf())
        if(deadlineTime < 0) return
        setDeadlineDay(parseInt(deadlineTime / (24 * 60 * 60 * 1000)))
        deadlineTime = parseInt(deadlineTime % (24 * 60 * 60 * 1000))
        setDeadlineHour(parseInt(deadlineTime / (60 * 60 * 1000)))
        deadlineTime = parseInt(deadlineTime % (60 * 60 * 1000))
        setDeadlineMin(parseInt(deadlineTime / (60 * 1000)))
        deadlineTime = parseInt(deadlineTime % (60 * 1000))
        setDeadlineSec(parseInt(deadlineTime / 1000))
    }


    const callAmountApi = async () => {
        return await $.ajax({
            url: process.env.REACT_APP_APIURL,
            method: "GET",
            dataType:'json',
        })
        .done((res) => {
            if(!res) return console.log("Failed api call")
            localStorage.icoData = JSON.stringify(res)
        })
        .fail((e) => { console.log("failed call api",e) })
    }


    const updateICO_Amount = async () => {
        // await callAmountApi()

        if(!localStorage.icoData) localStorage.icoData = '[{"name":"BUSD","amount":6253.4086579532},{"name":"USDC","amount":200.07230529855516},{"name":"USDT","amount":96246.34694279924},{"name":"USDM","amount":106907.90243842},{"name":"KRWM","amount":760220.9349319681},{"name":"VNDM","amount":0}]'

        const getIcoAmount = await JSON.parse(localStorage.icoData)
        if(!getIcoAmount) return console.log("No have ico data")
        icoUSDT = (getIcoAmount.find(item => item.name === 'USDT')).amount
        icoUSDC = (getIcoAmount.find(item => item.name === 'USDC')).amount
        icoBUSD = (getIcoAmount.find(item => item.name === 'BUSD')).amount

        icoUSDM = (getIcoAmount.find(item => item.name === 'USDM')).amount
        icoKRWM = (getIcoAmount.find(item => item.name === 'KRWM')).amount
        icoVNDM = (getIcoAmount.find(item => item.name === 'VNDM')).amount
        
        const icoAmount = icoUSDT + icoUSDC + icoBUSD + icoUSDM + icoKRWM + icoVNDM
        const preIcoAmount = 55090
        const allicoAmount = icoAmount + preIcoAmount
        if(allicoAmount < hardcapAmount) {
            setRaisedUSDT(allicoAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }))
            setRemainingFee(parseInt(icoAmount * exFEE + (preIcoAmount * 1000)))
            $('#usdtBar').width(icoUSDT / hardcapAmount * 100 + '%')
            $('#usdcBar').width(icoUSDC / hardcapAmount * 100 + '%')
            $('#busdBar').width(icoBUSD / hardcapAmount * 100 + '%')
            $('#usdmBar').width(icoUSDM / hardcapAmount * 100 + '%')
            $('#krwmBar').width(icoKRWM / hardcapAmount * 100 + '%')
            $('#vndmBar').width(icoVNDM / hardcapAmount * 100 + '%')
            $('#preIcoBar').width(preIcoAmount / hardcapAmount * 100 + '%')
        }
        if( icoAmount <= icoBonusGap[0] ) setBonusAmount(10)
        else if( icoAmount <= icoBonusGap[1] ) setBonusAmount(7)
        else if( icoAmount <= icoBonusGap[2] ) setBonusAmount(5)
        else if( icoAmount <= icoBonusGap[3] ) setBonusAmount(3)
        else setBonusAmount(0)
    }

    useEffect(() => {
        
    })

    useEffect(() => {
        setInterval(() => {
            setDeadlineTimer()
        }, 1000)
    }, [])

    useEffect(() => {
        const f_updateICO_Amount = async () => {
            await updateICO_Amount()
        }
        f_updateICO_Amount()
        setInterval( async () => {
            await updateICO_Amount()
        }, (1*60000)) //1min
    }, [openICO])

    return (
        <>
            <div id='main' className='content'>
                
                <div className='mainInfo'>
                    <div className='notice'>
                        <p className='fsMT'>{t('notice_MT')}</p>
                        <p className='fsST'>{t('notice_ST')}</p>
                        <p className='fsNT'><Trans>notice_NT</Trans>
                        </p>
                        <p><Trans>notice_preContent</Trans>
                        <span className='red'><Trans>notice_preRedContent</Trans>{bonusAmount}<Trans>notice_sufRedContent</Trans></span>
                        <Trans>notice_sufContent</Trans>
              
                        </p>
                        <p className='bold6'>1 USDT = {exFEE} Fee <br />(Softcap : $500,000 / Hardcap : ${hardcapAmount.toLocaleString()})<br />USD Raised : ${raisedUSDT}
                        </p>
                    </div>
                    <div className='deadlineInfo'>
                        <div className='hardCap'>
                            <p className='fsST'>{(remainingTotal - remainingFee).toLocaleString()} ϕ</p>
                            <p className='fsNT'>FEE{t('hardCap_Remaining')}</p>
                        </div>
                            <a className='purchaseButton bold6' onClick={()=>{$("html, body").animate({scrollTop: ($("#purchase").offset()).top}, 500)}}><p>BUY</p></a>
                            <p className='fsNT'>{t('hardCap_DeadLine')}</p>
                        <div id='timer'>
                            <div><span>{deadlineDay}</span><span className='timerFormat'>{t('hardCap_DeadLineDay')}</span></div>
                            <span>:</span>
                            <div><span>{deadlineHour}</span><span className='timerFormat'>{t('hardCap_DeadLineHour')}</span></div>
                            <span>:</span>
                            <div><span>{deadlineMin}</span><span className='timerFormat'>{t('hardCap_DeadLineMin')}</span></div>
                            <span>:</span>
                            <div><span>{deadlineSec}</span><span className='timerFormat'>{t('hardCap_DeadLineSec')}</span></div>
                        </div>
                    </div>
                </div>
                <div className='progressBar'>
                    <div id='maxBar'>
                        <div className='softcapReached'>
                        <img src={require('../../img/softcapReached.png')} />
                        </div>
                        <div className='softCap'></div>
                        <div id='currentBar'>
                            <div id='usdtBar' className='usdtIndex'></div>
                            <div id='usdcBar' className='usdcIndex'></div>
                            <div id='busd_Bar' className='bnsdIndex'></div>
                            <div id='usdmBar' className='usdmIndex'></div>
                            <div id='krwmBar' className='krwmIndex'></div>
                            <div id='vndmBar' className='vndmIndex'></div>
                            <div id='preIcoBar' className='preIcoIndex'></div>
                        </div>
                    </div>
                    <div className='softCapText'>
                        <p>Softcap</p>
                    </div>
                    <div className='IcoCurrencyList'>
                        <div><img src={require('../../img/icon_usdt.webp')} />USDT(ETH, BNB, MATIC)</div>
                        <div><img src={require('../../img/icon_usdc.webp')} />USDC(ETH, BNB, MATIC)</div>
                        <div><img src={require('../../img/icon_busd.webp')} />BUSD(ETH, BNB, MATIC)</div>
                        <div><img src={require('../../img/icon_usdm.png')} />USDM</div>
                        <div><img src={require('../../img/icon_krwm.png')} />KRWM</div>
                        <div><img src={require('../../img/icon_vndm.png')} />VNDM</div>
                        <div><img src={require('../../img/icon_preico.png')} />PreICO</div>
                    </div>
                </div>
            </div>
        </>
    );


}

export default Main;