import { useTranslation} from 'react-i18next'
import $ from 'jquery';

const Metamask = () => {
    const [t] = useTranslation();

    const copyText = (type, index = '') => {
        let setCopyText
        if (type === 'network') {
            setCopyText = "Mojaik(s" + index + ")"
        } else if (type === 'rpcUrl') {
            setCopyText = "https://rpc.s" + index + ".babylon.mojaik.com"
        } else if (type === 'chainId') {
            setCopyText = "168284800" + index
        } else if (type === 'address') {
            setCopyText = index
        } else if (type === 'explorerUrl') {
            setCopyText = "https://" + index
            index = ''
        } else return
        $('.networkName a').css('color', '#4080FF')
        $('.copyAddress').css('background', '#DC3545')
        const mergeClass = '.'+ type + index
        if(type === 'copyAddress') $(mergeClass).css('background', 'darkslateblue')
        else $(mergeClass).css('color', 'darkslateblue')
        navigator.clipboard.writeText(setCopyText)
    }

    const changeSubTitle = (side) => {
        $('.networkSub>div').css('color', '#DC3545')
        $('.networkSub>div').css('background', 'white')
        $('.networkText').hide()
        const mergeClass = '.networkSub_'+ side
        $(mergeClass).css('color', 'white')
        $(mergeClass).css('background', '#DC3545')
        if(side == 'right') {
            $('#MojaikNetworkText').show()
        } else {
            $('#PolygonNetworkText').show()
        }
    }

    return (
        <>
            <div id='exchange_Network' className='exchangePart content'>
                <p className='fsMT'>{t('purchase_network_Title')}</p>

                <div className='targetAddress subTitle networkSub'>
                    <div className='networkSub_left' onClick={() => changeSubTitle('left')}>Polygon</div>
                    <div className='networkSub_right' onClick={() => changeSubTitle('right')}>Mojaik Babylon</div>
                </div>

                <table className='networkName'>
                    <thead>
                        <tr>
                            <th colSpan={3}>{t('purchase_mainetTitle')}</th>
                        </tr>
                    </thead>
                    <tbody id='PolygonNetworkText' className='networkText'>
                        <tr>
                            <td>MOJAIK FEE TOKEN CONTRACT: <a className='address0xD01761aD2C5eb0d6d6AF52b0c48c0c0eE4BCBaf1' onClick={() => copyText('address', '0xD01761aD2C5eb0d6d6AF52b0c48c0c0eE4BCBaf1')}>0xD01761aD2C5eb0d6d6AF52b0c48c0c0eE4BCBaf1</a></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                            <div className='explanationImg'>
                                <img src={require('../../img/polygonFEE1.png')} loading="lazy"/>
                                <img src={require('../../img/polygonFEE2.png')} loading="lazy"/>
                                <img src={require('../../img/polygonFEE3.png')} loading="lazy"/>
                                {/* <img src={require('../../img/polygonFEE4.png')} loading="lazy"/> */}
                                <img src={require('../../img/polygonFEE5.png')} loading="lazy"/>
                                <img src={require('../../img/polygonFEE6.png')} loading="lazy"/>
                                <img src={require('../../img/polygonFEE7.png')} loading="lazy"/>
                                {/* <img src={require('../../img/polygonFEE8.png')} loading="lazy"/> */}
                            </div>
                            </td>
                        </tr>                       
                    </tbody>
                    <tbody id='MojaikNetworkText' className='networkText'>
                        <tr>
                            <td><a className='network0' onClick={() => copyText('network', 0)}>Mojaik(s0)</a></td>
                            <td>RPC URL: <a className='rpcUrl0' onClick={() => copyText('rpcUrl', 0)}>rpc.s0.babylon.mojaik.com</a></td>
                            <td>Chain id: <a className='chainId0' onClick={() => copyText('chainId', 0)}>1682848000</a></td>
                        </tr>
                        <tr>
                            <td><a className='network1' onClick={() => copyText('network', 1)}>Mojaik(s1)</a></td>
                            <td>RPC URL: <a className='rpcUrl1' onClick={() => copyText('rpcUrl', 1)}>rpc.s1.babylon.mojaik.com</a></td>
                            <td>Chain id: <a className='chainId1' onClick={() => copyText('chainId', 1)}>1682848001</a></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>EXPLORER URL: <a className='explorerUrl' onClick={() => copyText('explorerUrl', 'exp.mojaik.com')}>exp.mojaik.com</a></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                            <div className='explanationImg'>
                                <img src={require('../../img/metamask1.png')} loading="lazy"/>
                                <img src={require('../../img/metamask2.png')} loading="lazy"/>
                                <img src={require('../../img/metamask3.png')} loading="lazy"/>
                                <img src={require('../../img/metamask4.png')} loading="lazy"/>
                                <img src={require('../../img/metamask5.png')} loading="lazy"/>
                                <img src={require('../../img/metamask6.png')} loading="lazy"/>
                            </div>
                            </td>
                        </tr>                                            
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Metamask;