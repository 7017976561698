import { useTranslation, Trans} from 'react-i18next'

const Baduk = () => {
	const [t] = useTranslation();
	return (
		<>
			<div id='baduk' className='content'>
				<div className="bgImgContaner">
					<img src={require('../../img/badukBg.png')} loading="lazy"/>
				</div>
				{/* <p className='fsMT'>{t('menu_About')}</p> */}
				<p className='fsMT'>{t('part_MT')}</p>
				<p className='fsST'>{t('baduk_MT')}</p>
				<div className='collaboration'>
					<div className='badukImg'>
						<img className='badukBottomImg' src={require('../../img/baduk_bottom.png')} loading="lazy"/>
						<img className='badukTopImg' src={require('../../img/baduk_top.png')} loading="lazy"/>
					</div>
					<div className='badukText'>
						<p className='fsST'>{t('baduk_ST')}</p>
						<p><Trans>baduk_Content</Trans>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Baduk;