import React, { useEffect } from 'react';
import $ from 'jquery';

const Webtoon = () => {
    const webtoonList = [7, 7, 5, 5, 5, 5, 5, 5, 5, 5]

    if (!localStorage.webtoonPage) localStorage.webtoonPage = 3
    if (!localStorage.webtoonEp) localStorage.webtoonEp = 1

    const setWebtoonImg = () => {
        const lang = localStorage.lang === 'ko' ? 'ko' : 'en'
        for(let i = 1; i <= 7; i ++) $('.webtoonImg'+i).attr('src', '')
        for(let i = 1; i <= webtoonList[localStorage.webtoonEp - 1]; i ++) $('.webtoonImg'+i).attr('src',require('../img/webtoon/'+ lang +'_'+ localStorage.webtoonEp +'-'+ i +'.png'))
    }   

    const chooseWebtoonItme = (index) => {
        const epIndex = ($('#webtoonEpItem' + index).text()).slice(3)
        if(parseInt(localStorage.webtoonEp) === epIndex || webtoonList.length < epIndex ) return
        localStorage.webtoonEp = epIndex
        changeEp()
        setWebtoonImg()
    }

    const setWebtoonList = (skip) => {
        let webtoonPage = parseInt(localStorage.webtoonPage)
        const webtoonEp = parseInt(localStorage.webtoonEp)
        let epList        
        if(skip === 'set') {
            if(webtoonList.length - 1 <= webtoonEp) epList = [webtoonList.length - 4, webtoonList.length - 3, webtoonList.length - 2, webtoonList.length - 1, webtoonList.length]
            else if(webtoonEp > 3) epList = [webtoonEp - 2, webtoonEp - 1, webtoonEp, webtoonEp + 1, webtoonEp + 2]
            else epList = [1,2,3,4,5]
        } else if(skip === 'next') {
            if(webtoonPage + 7 <= webtoonList.length) {
                webtoonPage += 5
                epList = [webtoonPage - 2, webtoonPage - 1, webtoonPage, webtoonPage + 1, webtoonPage + 2]
            } else if(webtoonPage + 3 <= webtoonList.length) {
                webtoonPage = webtoonList.length - 2
                epList = [webtoonList.length - 4, webtoonList.length - 3, webtoonList.length - 2, webtoonList.length - 1, webtoonList.length]
            }
        } else if(skip === 'back') {
            if(webtoonPage > 5 && webtoonPage - 5 > 3) {
                webtoonPage -= 5
                epList = [webtoonPage - 2, webtoonPage - 1, webtoonPage, webtoonPage + 1, webtoonPage + 2]
            } else {
                epList = [1,2,3,4,5]
                webtoonPage = 3
            }
        }

        if(webtoonPage <= 3) $('#webtoonEpSkipBack').css('color','#ccc')
        else $('#webtoonEpSkipBack').css('color','#4E5969')
        if(webtoonList.length < webtoonPage + 3) $('#webtoonEpSkipNext').css('color','#ccc')
        else $('#webtoonEpSkipNext').css('color','#4E5969')
        

        if(epList) {
            localStorage.webtoonPage = webtoonPage
            $('#webtoonEpItem0').text('Ep.'+epList[0])
            $('#webtoonEpItem1').text('Ep.'+epList[1])
            $('#webtoonEpItem2').text('Ep.'+epList[2])
            $('#webtoonEpItem3').text('Ep.'+epList[3])
            $('#webtoonEpItem4').text('Ep.'+epList[4])
            if(webtoonList.length < epList[3]) $('#webtoonEpItem3').css('color','#ccc')
            else $('#webtoonEpItem3').css('color','#4E5969')
            if(webtoonList.length < epList[4]) $('#webtoonEpItem4').css('color','#ccc')
            else $('#webtoonEpItem4').css('color','#4E5969')
        }
        
    }
    
    const showWebtoonList = () => {
        if($('.webtoonDropList').is(":hidden")) {
            $('.webtoonDropList').css('display', 'flex')
            $('.webtoonExpand').text('Fold')
        } else {
            $('.webtoonExpand').text('Open')
            $('.webtoonDropList').hide()
        }
    }

    const changeEp = (event) => {
        const webtoonEp = parseInt(localStorage.webtoonEp)
        if(event === 'next') {
            if(webtoonList.length <= webtoonEp) return
            else if((webtoonList.length - 1) === webtoonEp) $('#webtoonEpNext').css('color','#ccc')
           
            $('#webtoonEpBack').css('color','#4080FF') 
            localStorage.webtoonEp = webtoonEp + 1
            localStorage.webtoonPage = webtoonEp >= 3 ? webtoonEp + 1 : 3
        } else if(event === 'back') {
            if(webtoonEp === 1) return
            else if(webtoonEp === 2) $('#webtoonEpBack').css('color','#ccc')

            $('#webtoonEpNext').css('color','#4080FF')
            localStorage.webtoonEp = webtoonEp - 1
            localStorage.webtoonPage = webtoonEp > 3 ? webtoonEp - 1 : 3
        } else {
            if(webtoonEp === 1) $('#webtoonEpBack').css('color','#ccc')
            else if(webtoonList.length === webtoonEp) $('#webtoonEpNext').css('color','#ccc')
            localStorage.webtoonEp = webtoonEp
            localStorage.webtoonPage = webtoonEp > 3 ? webtoonEp : 3
        }
        if(webtoonEp >= 2 && event !== 'back') $('#webtoonEpBack').css('color','#4080FF') 
        if((webtoonList.length - 1) >= webtoonEp && event !== 'next') $('#webtoonEpNext').css('color','#4080FF')

        $('.webtoonPage span').text('Ep.' + localStorage.webtoonEp)
        $('.webtoonExpand').text('Open')
        $('.webtoonDropList').hide()
        setWebtoonList('set')
        setWebtoonImg()
    }

    useEffect(() => {
        const getWindowWidth = $(window).width()
        let webtoonFrameHeight
        if(getWindowWidth <= 320) webtoonFrameHeight = (56 + 135)
        else if(getWindowWidth <= 768) webtoonFrameHeight = (56 + 121)
        else if(getWindowWidth <= 1024) webtoonFrameHeight = (64 + 72)
        else if(getWindowWidth <= 1440) webtoonFrameHeight = (88 + 108)
        else webtoonFrameHeight = (124 + 148)
        $('.webtoonImgFrame').height($(window).height() - (webtoonFrameHeight + 64))
        
        setWebtoonImg()
        setWebtoonList('set')
    },[])

    return (
        <>
            <div id='webtoon' className='content'>
                <div className='webtoonList'>
                    <div></div>
                    <div className='webtoonPage'>
                        <a id='webtoonEpBack' onClick={()=> changeEp('back')}> {"<"} </a>
                        <span>Ep.{localStorage.webtoonEp}</span>
                        <a id='webtoonEpNext' onClick={()=> changeEp('next')}> {">"} </a>
                    </div>
                    <div>
                        <a className='webtoonExpand' onClick={() => showWebtoonList()}>Open</a>
                        <div className='webtoonDropList'>
                        <p id="webtoonEpSkipBack" onClick={() => setWebtoonList("back")}> {"<"} </p>
                        <p id='webtoonEpItem0' className='webtoonEpItem' onClick={() => chooseWebtoonItme(0)}>Ep.1</p>
                        <p id='webtoonEpItem1' className='webtoonEpItem' onClick={() => chooseWebtoonItme(1)}>Ep.2</p>
                        <p id='webtoonEpItem2' className='webtoonEpItem' onClick={() => chooseWebtoonItme(2)}>Ep.3</p>
                        <p id='webtoonEpItem3' className='webtoonEpItem' onClick={() => chooseWebtoonItme(3)}>Ep.4</p>
                        <p id='webtoonEpItem4' className='webtoonEpItem' onClick={() => chooseWebtoonItme(4)}>Ep.5</p>
                        <p id="webtoonEpSkipNext" onClick={() => setWebtoonList("next")}> {">"} </p>
                        </div>
                    </div>
                </div>
                <div className='webtoonImgFrame'>
                    <img className='webtoonImg1 webtoonImg' loading="lazy"/>
                    <img className='webtoonImg2 webtoonImg' loading="lazy"/>
                    <img className='webtoonImg3 webtoonImg' loading="lazy"/>
                    <img className='webtoonImg4 webtoonImg' loading="lazy"/>
                    <img className='webtoonImg5 webtoonImg' loading="lazy"/>
                    <img className='webtoonImg6 webtoonImg' loading="lazy"/>
                    <img className='webtoonImg7 webtoonImg' loading="lazy"/>
                </div>
            </div>
        </>
    );
};

export default Webtoon;