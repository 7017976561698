import { useTranslation, Trans } from 'react-i18next'

const Timestope = () => {
    const [t] = useTranslation();
    return (
        <>
            <div id='timestope' className='content'>
                <div className='timestopeApp'>
                    {/* <p className='fsMT'>{t('ceo_TimestopeAppText')}</p> */}
                    <p className='fsST'>{t('ceo_TimestopeAppTextPart2')}</p>
                    <img src={require('../../img/androidTimestope.png')} loading="lazy" />
                    <a href='https://play.google.com/store/apps/details?id=com.timestope'>Timestope App(Google Play Link)</a>
                </div>
            </div>
        </>
    );
};

export default Timestope;