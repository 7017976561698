import Downloads from './downloads';

const Download = () => {
	return (
        <>
		<Downloads />
        </>
	);
};

export default Download;