import { useTranslation, Trans } from 'react-i18next'

const Economy = () => {
    const [t] = useTranslation();
    return (
        <>
            <div id='economy' className='content'>            
                {/* <p className='fsMT'>{t('menu_Economy')}</p> */}
                <div className='intro'>
                    <img className='introLogo' src={require('../../img/logo_360px.png')} />
                    <p><Trans>economy_IntroContent</Trans>
                    </p>
                </div>
                <div className='detail'>
                    <div className='detailItem1 detailItem'>
                        <img className='introLogo' src={require('../../img/detail_Icon1.png')} loading="lazy"/>
                        <p className='detailTitle'>{t('economy_Detail1_Title')}</p>
                        <p><Trans>economy_Detail1_Content</Trans></p>
                    </div>
                    <div className='detailItem2 detailItem'>
                        <img className='introLogo' src={require('../../img/detail_Icon2.png')} loading="lazy"/>
                        <p className='detailTitle'>{t('economy_Detail2_Title')}</p>
                        <p><Trans>economy_Detail2_Content</Trans></p>
                    </div>
                    <div className='detailItem3 detailItem'>
                        <img className='introLogo' src={require('../../img/detail_Icon3.png')} loading="lazy"/>
                        <p className='detailTitle'>{t('economy_Detail3_Title')}</p>
                        <p><Trans>economy_Detail3_Content</Trans></p>
                    </div>
                    <div className='detailItem4 detailItem'>
                        <img className='introLogo' src={require('../../img/detail_Icon4.png')} loading="lazy"/>
                        <p className='detailTitle'>{t('economy_Detail4_Title')}</p>
                        <p><Trans>economy_Detail4_Content</Trans></p>
                    </div>
                    <div className='detailItem5 detailItem'>
                        <img className='introLogo' src={require('../../img/detail_Icon5.png')} loading="lazy"/>
                        <p className='detailTitle'>{t('economy_Detail5_Title')}</p>
                        <p><Trans>economy_Detail5_Content</Trans></p>
                    </div>
                    <div className='detailItem6 detailItem'>
                        <img className='introLogo' src={require('../../img/detail_Icon6.png')} loading="lazy"/>
                        <p className='detailTitle'>{t('economy_Detail6_Title')}</p>
                        <p><Trans>economy_Detail6_Content</Trans></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Economy;